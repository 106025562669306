// Updated Footer.js
import React, { useState, useRef } from 'react';
import './Footer.css';
import { Toast } from 'primereact/toast';
import { addToWaitlist } from './JoinWaitlist'; // Import the reusable join waitlist function

const Footer = () => {
  const [email, setEmail] = useState('');
  const toast = useRef(null);

  // Scroll to a specific section by ID
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Handle adding email to the waitlist
  const handleJoinWaitlist = async () => {
    if (!email.trim()) {
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter a valid email address.' });
      return;
    }

    try {
      const successMessage = await addToWaitlist(email,'footer');
      toast.current.show({ severity: 'success', summary: 'Success', detail: successMessage });
      setEmail('');
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
    }
  };

  return (
    <footer className="footer">
      <Toast ref={toast} />
      <div className="footer-top">
        <h2>Join Waitlist</h2>
        <div className="waitlist-container">
          <input
            type="email"
            placeholder="Enter your email"
            className="waitlist-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="waitlist-button" onClick={handleJoinWaitlist}>Join Waitlist</button>
        </div>
      </div>

      <div className="footer-middle">
        <div className="footer-logo">
          <h3>FinMitr</h3>
        </div>
        <ul className="footer-nav">
          <li><button className="footer-nav-button" onClick={() => scrollToSection('hero')}>Explore</button></li>
          <li><button className="footer-nav-button" onClick={() => scrollToSection('finguru')}>FinGuru</button></li>
          <li><button className="footer-nav-button" onClick={() => scrollToSection('fimin')}>FiM Insights</button></li>
        </ul>
        <div className="contact-us">
          <h4>Contact Us</h4>
          <p>Email: support@finmitr.in</p>
        </div>
        <div className="social-icons">
          <a href="https://linkedin.com/company/finmitr1" target="_blank"><i className="fab fa-linkedin"></i></a>
          <a href="https://www.instagram.com/finmitrapp/" target="_blank"><i className="fab fa-instagram"></i></a>
        </div>
      </div>

      <div className="footer-bottom">
        <ul className="footer-links">
          <li><a href="./Privacy.html">Privacy Policy</a></li>
        </ul>
        <p>© Copyright 2024, All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;