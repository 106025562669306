// JoinWaitlist.js
import { db } from '../firebaseConfig'; // Import the Firebase configuration
import { collection, addDoc, doc, setDoc } from 'firebase/firestore'; // Firestore functions
import axios from 'axios'; // Import axios

// Function to get user's IP address
const getUserIP = async () => {
  try {
    const response = await axios.get('https://api64.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IP address: ', error);
    throw new Error('Unable to fetch IP address.');
  }
};

// Function to add an email to the Firestore waitlistEmails collection along with IP address
export const addToWaitlist = async (email,loc) => {
  if (!email || email.trim() === '') {
    throw new Error('Please enter a valid email address.');
  }

  try {
    const userIP = await getUserIP(); // Fetch user's IP address
    await addDoc(collection(db, 'waitlistEmails'), {
      email:email,
      ip: userIP,
      timestamp: new Date(),
      loc:loc
    });

    return 'Thank you for joining the waitlist!';
  } catch (error) {
    console.error('Error adding document: ', error);
    throw new Error('Error occurred while adding to the waitlist. Please try again later.');
  }
};