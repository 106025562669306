import React, { useState, useRef } from 'react';
import './assets/css/style.css'; // Import your CSS
import logo from './assets/images/logo.png'; // Import your images
import homeImage from './assets/images/homeimage3.png';
import dataImg from './assets/images/data.png';
import artificialImg from './assets/images/artificial.png';
import phonemock1 from './assets/images/phonemock1.png';
import phonemock2 from './assets/images/phonemock2.png';
import phonemock from './assets/images/phonemock.png';
import circle1 from './assets/images/circle1.png';
import circle2 from './assets/images/circle2.png';
import circle3 from './assets/images/circle3.png';
import clockk from './assets/images/clockk.png';
import Footer from './components/Footer';
import Carousel from './components/Carousel';
import FAQItem from './components/FAQItem';
import FeatureItem from './components/FeatureItem'; // Added reusable feature item component
import FimInsightsCard from './components/FimInsightsCard'; // Added reusable insights card component
import { PrimeReactProvider } from 'primereact/api';
import { addToWaitlist } from '../src/components/JoinWaitlist'; // Import the reusable join waitlist function
import { Toast } from 'primereact/toast';

function App() {
  const [isNavActive, setIsNavActive] = useState(false);
  const toast = useRef(null);
  const [email, setEmail] = useState('');

  // Function to handle nav toggling in the hamburger menu
  const toggleNav = () => {
    setIsNavActive(!isNavActive);
  };
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }

    setIsNavActive(false);
  };

  const handleJoinWaitlist = async () => {
    if (!email.trim()) {
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter a valid email address.' });
      return;
    }

    try {
      const successMessage = await addToWaitlist(email, 'header');
      toast.current.show({ severity: 'success', summary: 'Success', detail: successMessage });
      setEmail('');
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
    }
  };



  return (
    <PrimeReactProvider >
      <Toast ref={toast} />
      <div>
        <header id="header">
          <nav className="navbar">
            <div className="logo">
              <img src={logo} alt="FinMitr Logo" className="logo-image" /> FinMitr
            </div>
            <ul className={`nav-links ${isNavActive ? 'active' : ''}`}>
              <li><button className="nav-button" onClick={() => scrollToSection('hero')}>Explore</button></li>
              <li><button className="nav-button" onClick={() => scrollToSection('finguru')}>FinGuru</button></li>
              <li><button className="nav-button" onClick={() => scrollToSection('fimin')}>FiM Insights</button></li>
              <li><button className="nav-button" onClick={() => scrollToSection('contact')}>Contact Us</button></li>
            </ul>
            <div className="right-section" >
              <button className="btn-demo-wait" onClick={() => scrollToSection('contact')}>Join Waitlist</button>
            </div>
            <div className="hamburger" onClick={toggleNav}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </nav>
        </header>

        <section id="hero">
          <h1>Unlock Financial Intelligence</h1>
          <p>Ask our AI model, FinGuru, anything and everything about your personal finances.<br />
            Our app empowers you to spend smartly and invest wisely.</p>

          <div className="weird-search-container">
            <input type="email" className="weird-email-input" placeholder="Enter your email" value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="weird-demo-button" onClick={handleJoinWaitlist}>Join Waitlist</button>
          </div>

          <div className="privacy-text">
            <small>By clicking ‘Join Waitlist’ you agree to FinMitr’s <a href="./Privacy.html">Privacy Statement</a></small>
          </div>
          <div className="hero-images">
            <img src={homeImage} alt="Mockup of FinMitr app" className="mockup-image" style={{ width: '80%', marginTop: '10rem', marginBottom: '5rem' }} />
          </div>
        </section>

        <p className="fim-insights-subtitle">Partners and Collaborations</p>
        <Carousel />

        <section id="finguru" className="finguru-wrapper">
          <div className="finguru-section">
            <div className="finguru-content">
              <h1>FINGURU</h1>
              <h2>Traditional marketing and service tools don’t set you up to seize the opportunity AI brings</h2>
            </div>
            <div className="finguru-features">
              <FeatureItem
                icon={dataImg}
                title="Data-Driven Insights"
                description="FinGuru consolidates your financial information, providing comprehensive, personalized guidance tailored to your journey..."
              />
              <FeatureItem
                icon={artificialImg}
                title="Deep AI Integration"
                description="Unlike traditional tools, FinGuru uses advanced AI to offer actionable guidance for smarter financial decisions..."
              />
              <FeatureItem
                icon={artificialImg}
                title="Always Within Reach"
                description="With 24/7 accessibility, FinGuru ensures you’re never alone in your financial journey, delivering guidance whenever you need it."
              />
            </div>
          </div>
        </section>

        <section id="fimin" className="fim-insights-wrapper">
          <h2 className="fim-insights-title">Powerful FiM Insights</h2>
          <p className="fim-insights-subtitle">The Financial 3rd Eye</p>

          <div className="fim-insights-container">
            <FimInsightsCard
              title="Visualize Your Spending"
              description="Get clear, categorized insights into where your money is going. Identify trends and opportunities to save effortlessly."
              image={phonemock1}
            />
            <FimInsightsCard
              title="Smart Budgeting Goals"
              description="Set personalized budgets and track them in real-time. Receive instant alerts to help you stay on top of your finances."
              image={phonemock2}
              cardClass="green"
            />
            <FimInsightsCard
              title={`What-If Scenarios by AI`}
              description="Use AI-driven projections to see how financial changes shape your future and plan salary adjustments with confidence."
              image={phonemock}
              cardClass="pink"
            />
          </div>
        </section>

        <section className="upi-container">
          <div className="upi-content">
            <h6>Take Control of Your Finances: Track Goals, Spot Expenses, Stay Ahead</h6>
            <ul className="upi-list">
              <li className="upi-list-item">Set and track your financial goals with real-time progress updates and personalized strategies.</li>
              <li className="upi-list-item">Monitor your expenses and spot overspending areas with clear red and green flags.</li>
              <li className="upi-list-item">Recharge, pay bills, and shop online, all within the app</li>
            </ul>
            <button className="upi-cta-button">Coming Soon!</button>
          </div>
          <div className="upi-icons-container">
            <div className="upi-icon upi-icon-green" style={{ top: '15%', left: '10%' }}>
              <img src={circle1} alt="Financial planning icon" style={{padding:'0.8rem'}}/>
            </div>
            <div className="upi-icon upi-icon-blue" style={{ top: '60%', left: '5%' }}>
              <img src={circle2} alt="Expense monitoring icon" />
            </div>
            <div className="upi-icon upi-icon-blue" style={{ top: '25%', right: '15%' }}>
              <img src={circle3} alt="QR code payments icon" />
            </div>
            <div className="upi-icon upi-icon-green" style={{ bottom: '10%', right: '20%' }}>
              <img src={clockk} alt="24/7 availability icon" />
            </div>
          </div>
        </section>

        <section id="faq-section" className="faq-wrapper">
          <h2 className="faq-title">FAQs</h2>
          <div className="faq-container">
            <FAQItem question="What is FinMitr?" answer="FinMitr is an AI-driven financial management app that helps you track, manage, and optimize your finances effortlessly." />
            <FAQItem question="Who can use FinMitr?" answer="Anyone with an income who wants to manage their expenses, save more, and grow their wealth can benefit from FinMitr." />
            <FAQItem question="Is FinMitr free to use?" answer="Yes, FinMitr is currently free to use for users who join our waitlist." />
            <FAQItem question="How is FinMitr different from other financial apps?" answer="FinMitr integrates advanced AI guidance, personalized financial insights, and real-time tracking, making it a one-stop solution for managing your finances." />
            <FAQItem question="Is my financial data safe with FinMitr?" answer="Absolutely. We use bank-level encryption and security measures to ensure your data is fully protected." />
          </div>
        </section>
        <div id="contact">
          <Footer />
        </div>
      </div>
    </PrimeReactProvider>
  );
}

export default App;
