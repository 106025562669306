// FeatureItem.js
import React from 'react';
import './FeatureItem.css';

function FeatureItem({ icon, title, description }) {
  return (
    <div className="feature-item">
      <div className="icon">
        <img src={icon} alt={`${title} Icon`} />
      </div>
      <div className="text-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default FeatureItem;