// FAQItem.js
import React, { useState } from 'react';
import './FAQItem.css';

function FAQItem({ question, answer }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`faq-item ${isActive ? 'active' : ''}`}>
      <button className="faq-question" onClick={() => setIsActive(!isActive)}>
        {question}
      </button>
      {isActive && <div className="faq-answer">{answer}</div>}
    </div>
  );
}

export default FAQItem;

